import React, { useState, useEffect, useRef } from 'react';
import {
	Wrap,
	WrapItem,
	Button,
	FormControl,
	FormLabel,
	Tooltip,
	IconButton,
	VStack,
	HStack,
	Input,
	useToast,
	Box,
} from '@chakra-ui/react';
import { EditIcon, InfoIcon, RepeatIcon } from '@chakra-ui/icons';
import { supabase } from './index';

export const presets = [
	{
		label: 'blueberry, face',
		prompt: "In this image, we see a blonde haired woman with pigtails in the end stages of a blueberry inflation transformation. Her skin is blue and her body has rounded out into a massive sphere, with her appendages being pulled into tight divots on her body. The camera is positioned close up on her face but slightly above, as her cheeks swell and her inflating, spherical body pushes into her swollen face. Her round body takes up the entire frame from side to side. Her spherical body is tightly covered in a dark blue, matte, fabric material that is her, once appropriately fitting but now, incredibly stretched out dress. The background is dimly lit, whimsical chocolate factory. The lights in the background gives a blurred bokeh effect that produces a cinematic vibe. ((Her face is being sucked into the divot on her body where her neck used to be.)) ((Her body is incredibly wide, to the point that you cannot see where her round body curves downwards. It is stretched from one side of the frame to the other in a very shallow curve, and the picture is cropped so you can't see the background to either side of her swollen body.)) ((Her body is at least twenty feet in circumference.))",
		width: 1024,
		height: 1024,
		imageType: 'face'
	},
	{
		label: 'blueberry, face, widescreen',
		prompt: "In this image, a beautiful woman with brown hair and bangs is shown in the later stages of a blueberry inflation transformation. Her skin is blue with dark red blushing, and her face is tightly surrounded by her large, rounded body of which her head is sinking into. ((Her cheeks are fat and round as if she is holding her breath.)) ((She has an expression of pure embarrassment and shock.)) The camera is positioned below her, capturing the full extent of her transformation and the intensity of her facial expression as she rolls towards the camera. ((She is leaning forward and her hair is falling in front of her face.)) The background is blurry, dimly lit, and out of focus Factory, but with bright neon lights. The image appears to be from a movie where a character has inflated into a spherical blueberry. Her head is sinking into her body as she panics. ((Her spherical body is covered by a very tight, matte, dark blue fabric that appears to be her stretched out clothes without any wrinkles.)) ((The entire scene is very dimly lit, but has a dark cinematic lighting.)) ((The image has letterboxing as it is taken from a widescreen movie))",
		width: 1600,
		height: 1000,
		imageType: 'face'
	},
	{
		label: 'blueberry, backside, stuck',
		prompt: "In this image, we see the backside of a woman who is in the end stage of a blueberry inflation transformation, resulting in her body becoming a massive, dark blue, skin-textured spherical shape. Her clothes cling desperately to her body, completely stretched as they attempt to stay in one piece on her swelling body. To the sides of her crotch, are her swollen legs with her brown dress shoes being engulfed into her expanded form. She rests on her back, helplessly spherical, as she rolls back and her crotch points up towards the ceiling. Surrounding her, the environment suggests some kind of laboratory or workshop, with various pieces of equipment and machinery visible in the background. The scene takes place in a chocolate factory's inventing room which is evident by the scene being very brightly lit with whimsical colors. The image has a vertical aspect ratio as it appears to be from a phone video. The woman' shape is completely circular, resembling a massive twenty foot wide yoga ball. Her spherical body cannot even completely be in frame due to her sheer size. The woman is squeezed inside of a huge circular doorway that could fit an elephant, but cannot accommodate for her massive frame. Her body has a skin texture to it. The weight of her massive body causes her body to slightly protrude where it meets the floor.",
		width: 1600,
		height: 1000,
		imageType: 'backside'
	},
	{
		label: 'blueberry, goth, latex',
		prompt: 'In this image, a 20-year-old goth woman is shown in the later stages of a blueberry inflation transformation. ((She has straight black hair and very evenly cut bangs, giving her an edgy, goth look.)) Her face is tightly surrounded by her large, rounded body of which her head is sinking into. ((Her cheeks are tight and round as if she is holding in a very deep breath.)) ((She has an expression of extreme worry and pure embarrassment.)) (((The emo girl\'s eyes are extremely wide and bulging as she stares into the camera with a shoked expression))) (((The woman is wearing dark black eye shadow with bright red lipstick))) The camera is positioned above her as it captures her head sinking into the neck divot on her inflated body. ((Her skin is blue with very bright red blushing from embarrassment)) The background is a blurry, brightly lit, whimsical candy factory\'s inventing room, but with bright neon lights. The image appears to be from a movie where a character has inflated into an blueberry juice filled blimp, her spherical body taking up the entire frame from side to side. Her head is sinking into her body as her eyes dart around the room, searching for help. ((Her spherical body is covered by a very tight, red, shiny latex material with a straining zipper down the front. It appears to be her, once well-fitting but now completely taut stretched-out dress.)) ((The entire scene is very dimly lit, but has a dark cinematic lighting.)) ((The image has letterboxing as it is taken from a widescreen movie)), (((her face is sinking into the divot where her head sits, resulting in her body squeezing her face and cheeks.))) (((A finger reaches out and pokes her swollen body from behind the camera)))',
		width: 1600,
		height: 1000,
		imageType: 'face'
	},
	{
		label: 'air, backside, floating on chocolate river',
		prompt: "In this image, we see the backside of a woman who is in the end stage of an inflation transformation, resulting in her body becoming a massive, skin-textured, spherical shape. She appears to be a massive, peach-colored, blimp. Her incredibly stretched-out red, matte, fabric dress clings tightly to her body, constricting her air-filled body. To the sides of her crotch are her swollen legs with her brown dress shoes being engulfed into her expanded form. She rests on her back, helplessly spherical as she drifts lazily in a very wide, dark chocolate river. The river is surrounded by a industrial nature looking space, but instead of flora, the plants, grass, and trees are fantastical candy decorations. The entire scene is very dimly lit, but has a dark cinematic lighting with out-of-focus bokeh. The image has letterboxing as it is a screenshot from a widescreen formatted movie. ((The woman' shape is completely circular, resembling a massive twenty foot wide yoga ball.)) ((Her body has a skin texture to it.))",
		width: 1600,
		height: 1000,
		imageType: 'backside'
	},
	{
		label: 'top-down, body takes up entire frame',
		prompt: "((Deep blue indigo skin, blue skinned character.)) In this image, a beautiful woman with a blue face and blue hair and bangs is shown in the later stages of a blueberry inflation transformation. Her body takes up the entire frame like a blue wall, her tiny head centered in the frame. Her skin is blue with dark red blushing, and her face is tightly surrounded by her large, rounded body of which her head is sinking into. She has beautiful eyes looking upward. ((Her face is beautiful and gorgeous, but surprised and shocked.)) ((She has an expression of pure embarrassment and shock.)) The camera looks down on her, she looks up at us, her head is tiny compared to her gigantic body. ((She is leaning forward and her hair is falling in front of her face.)). ((Her spherical body is covered by a very tight, matte, dark blue fabric that appears to be her stretched out clothes without any wrinkles.)) ((The entire scene is very dimly lit, but has a dark cinematic lighting.))",
		width: 1200,
		height: 1200,
		imageType: 'face',
		info: 'Created by hgswells9000 - check out their work at https://www.deviantart.com/hgswells9000'
	},
	{
		label: 'Custom',
		prompt: '',
		width: 1024,
		height: 1024,
		imageType: 'face'
	},
];

const MAX_CUSTOM_PRESETS = 5;

const PresetSelector = ({ selectedPreset, onPresetSelect, fontSize = "xs", currentSettings, onCustomPresetsChange }) => {
	const [customPresets, setCustomPresets] = useState([]);
	const [renameIndex, setRenameIndex] = useState(null);
	const [newName, setNewName] = useState('');
	const effectRan = useRef(false);
	const toast = useToast();

	useEffect(() => {
		if (effectRan.current === false) {
			fetchCustomPresets();
			return () => {
				effectRan.current = true;
			};
		}
	}, []);

	const fetchCustomPresets = async () => {
		const { data: userData, error: userError } = await supabase.auth.getUser()
		if (userError) {
			console.error('Error fetching current user:', userError);
			return;
		}

		const { data, error } = await supabase
			.from('image_presets')
			.select()
			.eq("user_id", userData.user.id)
			.order('id');

		if (error) {
			console.error('Error fetching custom presets:', error);
			return;
		}

		let updatedPresets = [...data];
		const presetsToCreate = MAX_CUSTOM_PRESETS - data.length;

		if (presetsToCreate > 0) {
			const placeholderPresets = await createPlaceholderPresets(presetsToCreate);
			updatedPresets = [...updatedPresets, ...placeholderPresets];
		}

		setCustomPresets(updatedPresets);
		onCustomPresetsChange(updatedPresets);
	};

	const createPlaceholderPresets = async (count) => {
		const { data: userData, error: userError } = await supabase.auth.getUser()
		if (userError) {
			console.error('Error fetching current user:', userError);
			return;
		}
		const placeholders = [];
		for (let i = 0; i < count; i++) {
			const { data, error } = await supabase
				.from('image_presets')
				.insert({
					name: `Saved Preset ${customPresets.length + i + 1}`,
					user_id: userData.user.id,
					prompt: '',
					width: 1024,
					height: 1024,
					image_type: 'face'
				})
				.select()
				.single();

			if (error) {
				console.error('Error creating placeholder preset:', error);
			} else {
				placeholders.push(data);
			}
		}
		return placeholders;
	};

	const handleRename = async (index) => {
		if (newName.trim() === '') {
			toast({
				title: 'Error',
				description: 'Preset name cannot be empty',
				status: 'error',
				duration: 3000,
				isClosable: true,
			});
			return;
		}

		const preset = customPresets[index];
		const { error } = await supabase
			.from('image_presets')
			.update({ name: newName })
			.eq('id', preset.id);

		if (error) {
			console.error('Error updating preset name:', error);
			toast({
				title: 'Error',
				description: 'Failed to rename preset',
				status: 'error',
				duration: 3000,
				isClosable: true,
			});
		} else {
			const updatedPresets = [...customPresets];
			updatedPresets[index] = { ...preset, name: newName };
			setCustomPresets(updatedPresets);
			onCustomPresetsChange(updatedPresets);
			setRenameIndex(null);
			setNewName('');
			toast({
				title: 'Success',
				description: 'Preset renamed successfully',
				status: 'success',
				duration: 3000,
				isClosable: true,
			});
		}
	};

	const handleOverwrite = async (index) => {
		const preset = customPresets[index];
		const { error } = await supabase
			.from('image_presets')
			.update({
				prompt: currentSettings.prompt,
				width: currentSettings.width,
				height: currentSettings.height,
				image_type: currentSettings.selectedLora,
			})
			.eq('id', preset.id);

		if (error) {
			console.error('Error overwriting preset:', error);
			toast({
				title: 'Error',
				description: 'Failed to overwrite preset',
				status: 'error',
				duration: 3000,
				isClosable: true,
			});
		} else {
			const updatedPresets = [...customPresets];
			updatedPresets[index] = {
				...preset,
				prompt: currentSettings.prompt,
				width: currentSettings.width,
				height: currentSettings.height,
				image_type: currentSettings.selectedLora,
			};
			setCustomPresets(updatedPresets);
			onCustomPresetsChange(updatedPresets);
			toast({
				title: 'Success',
				description: 'Preset overwritten successfully',
				status: 'success',
				duration: 3000,
				isClosable: true,
			});
		}
	};

	return (
		<VStack align="stretch" spacing={4}>
			<FormControl>
				<FormLabel display="flex" alignItems="center">
					Presets
					<Tooltip label='Try one of these presets! You can edit them, or start from scratch with "Custom."' hasArrow>
						<IconButton
							icon={<InfoIcon />}
							size="xs"
							variant="ghost"
							ml={0}
							mb={1}
							aria-label="Presets information"
						/>
					</Tooltip>
				</FormLabel>
				<Wrap spacing={2}>
					{presets.map((preset, index) => (
						<WrapItem key={preset.label}>
							<Tooltip label={preset.info} isDisabled={!preset.info} hasArrow>
								<Button
									onClick={() => onPresetSelect(index)}
									colorScheme={selectedPreset === index ? 'blue' : 'gray'}
									fontSize={fontSize}
									height="auto"
									whiteSpace="normal"
									textAlign="center"
									px={3}
									py={2}
								>
									{preset.label}
								</Button>
							</Tooltip>
						</WrapItem>
					))}
				</Wrap>
			</FormControl>

			<FormControl>
				<FormLabel>
					Saved Presets
					<Tooltip label='Create a preset by clicking the overwrite button to save the current prompt settings.' hasArrow>
						<IconButton
							icon={<InfoIcon />}
							size="xs"
							variant="ghost"
							ml={0}
							mb={1}
							aria-label="Presets information"
						/>
					</Tooltip>
				</FormLabel>
				<Wrap spacing={2}>
					{customPresets.map((preset, index) => (
						<WrapItem key={preset.id}>
							<Box>
								{renameIndex === index ? (
									<Input
										value={newName}
										onChange={(e) => setNewName(e.target.value)}
										placeholder="New name"
										size="sm"
										maxLength={50}
										width="auto"
										mb={1}
									/>
								) : (
									<Button
										onClick={() => onPresetSelect(presets.length + index)}
										colorScheme={selectedPreset === presets.length + index ? 'blue' : 'gray'}
										fontSize={fontSize}
										height="auto"
										whiteSpace="normal"
										textAlign="center"
										px={3}
										py={2}
										mb={1}
									>
										{preset.name || `Custom Preset ${index + 1}`}
									</Button>
								)}
								<HStack spacing={1} justifyContent="center">
									{renameIndex === index ? (
										<>
											<Button size="xs" onClick={() => handleRename(index)}>Save</Button>
											<Button size="xs" onClick={() => setRenameIndex(null)}>Cancel</Button>
										</>
									) : (
										<>
											<Tooltip label="Rename">
												<IconButton
													icon={<EditIcon />}
													size="xs"
													aria-label="Rename preset"
													onClick={() => {
														setRenameIndex(index);
														setNewName(preset.name || `Custom Preset ${index + 1}`);
													}}
												/>
											</Tooltip>
											<Tooltip label="Overwrite">
												<IconButton
													icon={<RepeatIcon />}
													size="xs"
													aria-label="Overwrite preset"
													onClick={() => handleOverwrite(index)}
												/>
											</Tooltip>
										</>
									)}
								</HStack>
							</Box>
						</WrapItem>
					))}
				</Wrap>
			</FormControl>
		</VStack>
	);
};

export default PresetSelector;