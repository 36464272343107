import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import {
  Box,
  Container,
  VStack,
  Heading,
  Textarea,
  Input,
  Button,
  Image,
  useToast,
  FormControl,
  FormLabel,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  HStack,
  ButtonGroup,
  Text,
  Flex,
  useColorModeValue,
  SimpleGrid,
  Checkbox,
  Tooltip,
  IconButton,
  Progress,
} from '@chakra-ui/react';
import { supabase } from './index';
import { costToGen, timeToGen } from './calculations';
import { MAX_IMG_SIZE, MAX_PROMPT_CHARS, MAX_SEED_LENGTH, notEnoughTokensError } from './constants';
import ImageModal from './ImageModal';
import { useRollbar } from '@rollbar/react';
import { InfoIcon } from '@chakra-ui/icons';
import { sanitize } from 'dompurify';
import { estimatedTimeColor, tokenColor } from './colors';
import { BalloonIcon, BlueberryIcon } from './CustomIcons';
import PresetSelector, { presets } from './PresetComponent';
import { flattenImagesRow } from './imageUtils';
import { usePostHog } from 'posthog-js/react'

const generateRandomSeed = () => {
  // Generate a random number between 1000000 and 99999999
  const min = 10000000; // 8-digit number
  const max = 99999999; // 8-digit number

  return Math.floor(Math.random() * (max - min + 1) + min).toString();
};

const loadingMessages = [
  "Getting the juicer ready...",
  "Baking a blueberry pie...",
  "Topping up with air compressor...",
  "Paging the oompa loompas...",
  "Getting a bigger forklift...",
  "Writing new songs...",
  "Buying more belts...",
  "Adding 10% more lift to fizzy lifting drinks...",
  "Popping stitches...",
  "Stretching fabric...",
  "Squeezing in more juice...",
  "Cleaning juicing room...",
  "Registering for the country fair...",
  "Taking cover...",
  "Preparing three course meals...",
  "Attaching nozzles...",
  "Measuring air pressure...",
  "Calibrating scales...",
  "Checking ripeness..."
];

function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

function DimensionInput({ value, onChange, label }) {
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const debouncedOnChange = useCallback(
    debounce((newValue) => {
      onChange(newValue);
    }, 300),
    [onChange]
  );

  const handleSliderChange = useCallback((newValue) => {
    setLocalValue(newValue);
    debouncedOnChange(newValue);
  }, [debouncedOnChange]);

  const handleNumberInputChange = useCallback((valueString) => {
    const newValue = Number(valueString);
    if (!isNaN(newValue) && newValue >= 0 && newValue <= MAX_IMG_SIZE) {
      setLocalValue(newValue);
      debouncedOnChange(newValue);
    }
  }, [debouncedOnChange]);

  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <HStack spacing={4}>
        <NumberInput
          maxW="120px"
          mr="2rem"
          value={localValue}
          onChange={handleNumberInputChange}
          min={1}
          max={MAX_IMG_SIZE}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        <Slider
          flex="1"
          focusThumbOnChange={false}
          value={localValue}
          onChange={handleSliderChange}
          min={1}
          max={MAX_IMG_SIZE}
        >
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb fontSize="sm" boxSize="32px">
            <></>
          </SliderThumb>
        </Slider>
      </HStack>
    </FormControl>
  );
}

function isSameImagesObject(images1, images2) {
  return images1.id === images2.id && images1.image_urls.length === images2.image_urls.length;
}

const getRandomMessage = (currentMessage) => {
  let newMessage;
  do {
    newMessage = loadingMessages[Math.floor(Math.random() * loadingMessages.length)];
  } while (newMessage === currentMessage && loadingMessages.length > 1);
  return newMessage;
};

function CreatePage() {
  const [prompt, setPrompt] = useState('');
  const [seed, setSeed] = useState(generateRandomSeed());
  const [randomizeSeed, setRandomizeSeed] = useState(true);
  const [width, setWidth] = useState(1024);
  const [height, setHeight] = useState(1024);
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLora, setSelectedLora] = useState('face');
  const [selectedQuality, setSelectedQuality] = useState('medium');
  const [numImages, setNumImages] = useState(2);
  const [imageGenId, setImageGenId] = useState(null);
  const [session, setSession] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [selectedPreset, setSelectedPreset] = useState(1);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [showLoadingBar, setShowLoadingBar] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(loadingMessages[0]);
  const [loadingStartTime, setLoadingStartTime] = useState(null);
  const [estimatedLoadingTime, setEstimatedLoadingTime] = useState(0);
  const [customPresets, setCustomPresets] = useState([]);
  const loadingIntervalRef = useRef(null);
  const messageIntervalRef = useRef(null);
  const toast = useToast();
  const imageGenIdRef = useRef(imageGenId);
  const imagesRef = useRef(images);
  const animationFrameRef = useRef(null);
  const lastMessageUpdateTimeRef = useRef(0);

  const posthog = usePostHog()

  const rollbar = useRollbar();

  const updateLoadingProgress = useCallback(() => {
    if (!loadingStartTime) return;

    const elapsedTime = Date.now() - loadingStartTime;
    const progress = Math.min((elapsedTime / estimatedLoadingTime) * 100, 100);
    setLoadingProgress(progress);

    // Update loading message if 3 seconds have passed since the last update
    if (Date.now() - lastMessageUpdateTimeRef.current >= 3000) {
      setLoadingMessage(prevMessage => getRandomMessage(prevMessage));
      lastMessageUpdateTimeRef.current = Date.now();
    }

    animationFrameRef.current = requestAnimationFrame(updateLoadingProgress);

  }, [loadingStartTime, estimatedLoadingTime]);

  useEffect(() => {
    let visibilityChangeHandler;

    if (showLoadingBar && loadingStartTime) {
      animationFrameRef.current = requestAnimationFrame(updateLoadingProgress);

      // Set up visibility change handler
      visibilityChangeHandler = () => {
        if (!document.hidden) {
          // Tab has become active
          lastMessageUpdateTimeRef.current = 0; // Force message update on next frame
          if (animationFrameRef.current) {
            cancelAnimationFrame(animationFrameRef.current);
          }
          animationFrameRef.current = requestAnimationFrame(updateLoadingProgress);
        }
      };

      document.addEventListener('visibilitychange', visibilityChangeHandler);
    }

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
      if (visibilityChangeHandler) {
        document.removeEventListener('visibilitychange', visibilityChangeHandler);
      }
    };
  }, [showLoadingBar, loadingStartTime, updateLoadingProgress]);

  const handlePromptChange = (e) => {
    const newPrompt = e.target.value;
    if (newPrompt.length <= MAX_PROMPT_CHARS) {
      setPrompt(newPrompt);
    }
  };

  const handleNewImageGenData = useCallback(async (imageGenData) => {
    console.log("Received imageGenData:", imageGenData);
    if (imageGenData.status === 'completed' && !isSameImagesObject(imageGenData, imagesRef.current)) {
      console.log("Status is completed and images are different");
      try {
        const flattenedImages = await flattenImagesRow(imageGenData);
        setImages(flattenedImages);
      } catch (error) {
        rollbar.error('Error flattening images', { error, image_gen_id: imageGenData.id });
      } finally {
        setIsLoading(false);
        setShowLoadingBar(false);
        clearInterval(loadingIntervalRef.current);
        clearInterval(messageIntervalRef.current);
      }

      if (randomizeSeed) {
        setSeed(generateRandomSeed());
      }
    } else if (imageGenData.status === 'failed') {
      rollbar.error('Image Gen Failed For User.', { image_gen_id: imageGenData.id });
      toast({
        title: 'Error',
        description: `Something went wrong. Please try again.`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false);
      setShowLoadingBar(false);
      clearInterval(loadingIntervalRef.current);
      clearInterval(messageIntervalRef.current);
    } else {
      rollbar.error("Unexpected image generation status:", imageGenData.status);
    }
  }, [randomizeSeed, toast, rollbar]);

  useEffect(() => {
    imageGenIdRef.current = imageGenId;
    imagesRef.current = images;
  }, [imageGenId, images]);

  const estimatedTime = useMemo(() => {
    return timeToGen(width, height, selectedQuality, numImages);
  }, [width, height, selectedQuality, numImages]);

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setIsModalOpen(true);
  };

  const handleModalIndexChange = useCallback((newIndex) => {
    setCurrentImageIndex(newIndex);
  }, []);

  const handleFavoriteToggle = useCallback((index, isFavorited, favoritedId = null) => {
    setImages(prevImages => {
      const newImages = [...prevImages];
      newImages[index] = { ...newImages[index], isFavorited, favoritedId };
      return newImages;
    });
  }, []);

  const tokenCost = useMemo(() => {
    return costToGen(width, height, selectedQuality, numImages);
  }, [numImages]);

  const handlePresetSelect = (presetIndex) => {
    setSelectedPreset(presetIndex);
    let preset;
    if (presetIndex < presets.length) {
      preset = presets[presetIndex];
    } else {
      preset = customPresets[presetIndex - presets.length];
    }
    setPrompt(preset.prompt);
    setWidth(preset.width);
    setHeight(preset.height);
    setSelectedLora(preset.image_type || preset.imageType);
  };

  const handleCustomPresetsChange = (newCustomPresets) => {
    setCustomPresets(newCustomPresets);
  };

  useEffect(() => {
    handlePresetSelect(selectedPreset);
  }, []);

  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
    })

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })

    return () => subscription.unsubscribe()
  }, [])

  const fetchImageGenData = useCallback(async () => {
    if (!imageGenIdRef.current) return;

    if (!isLoading) return;

    try {
      const { data: imageGenData, error: imageGenFetchError } = await supabase
        .from('image_gens')
        .select()
        .eq('id', imageGenIdRef.current)
        .maybeSingle();

      if (imageGenFetchError) {
        throw imageGenFetchError;
      }

      if (imageGenData) {
        console.log("got image gen data manually")
        handleNewImageGenData(imageGenData);
      } else {
        console.log("did not find anything manually")
      }
    } catch (error) {
      rollbar.error("Error manually fetching image gen data:", error);
    }
  }, [handleNewImageGenData, rollbar]);

  useEffect(() => {
    if (!session) return;

    const channel = supabase
      .channel('image_gen_updates')
      .on(
        'postgres_changes',
        {
          event: 'UPDATE',
          schema: 'public',
          table: 'image_gens',
          filter: `user_id=eq.${session.user.id}`,
        },
        (payload) => {
          console.log("got payload:", payload.new)
          console.log("waiting for imageGenId:", imageGenIdRef.current)
          if (payload.new.id === imageGenIdRef.current) {
            handleNewImageGenData(payload.new)
          }
        }
      )
      .subscribe();

    console.log("also checking manually in case we missed the subscription update")
    fetchImageGenData();

    return () => {
      supabase.removeChannel(channel);
      clearInterval(loadingIntervalRef.current);
      clearInterval(messageIntervalRef.current);
    };
  }, [session]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setShowLoadingBar(true);
    setLoadingProgress(0);
    setLoadingMessage(getRandomMessage(''));
    lastMessageUpdateTimeRef.current = Date.now();

    const estimatedTime = timeToGen(width, height, selectedQuality, numImages);
    setEstimatedLoadingTime(estimatedTime * 2 * 1000); // Convert to milliseconds and double it
    setLoadingStartTime(Date.now());

    try {
      const { data: { session } } = await supabase.auth.getSession()

      const sanitizedPrompt = sanitize(prompt);
      if (isNaN(seed)) {
        throw new Error('Seed must be a number');
      }
      const seedLength = seed.length
      if (seedLength > MAX_SEED_LENGTH) {
        throw new Error(`Seed length of ${seedLength} is larger than limit of ${MAX_SEED_LENGTH}`)
      }

      const response = await fetch('/api/proxy_route', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session.access_token}`,
        },
        body: JSON.stringify({
          prompt: sanitizedPrompt,
          seed: seed,
          width: width,
          height: height,
          selectedLora: selectedLora,
          selectedQuality: selectedQuality,
          numImages: numImages
        }),
      });

      // Log the event to PostHog
      posthog.capture('generated images', {
        numImages: numImages,
        width: width,
        height: height,
        selectedLora: selectedLora,
        selectedQuality: selectedQuality,
        responseStatus: response.status
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'An error occurred');
      }

      const data = await response.json();
      console.log(data)
      setImageGenId(data.id);

    } catch (error) {
      // we don't want to report a not enough tokens error as that's not really an error
      if (error.message !== notEnoughTokensError) {
        rollbar.error('Error starting image generation:', error);
      }

      toast({
        title: 'Error',
        description: `Failed to start image generation: ${error.message}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false)
      setShowLoadingBar(false);
      setLoadingStartTime(null);
    }
  };

  return (
    <Box position="relative" minHeight="100vh" pb="60px" bg={bgColor}>
      <Container maxW="container.xl" py={10}>
        <VStack spacing={6}>
          <Flex align="center">
            <Heading>
              Image Generator
            </Heading>
            <BlueberryIcon boxSize={6} ml={2} color="blue.500" />
            <BalloonIcon boxSize={6} ml={0} color="red.500" />
          </Flex>
          <PresetSelector
            selectedPreset={selectedPreset}
            onPresetSelect={handlePresetSelect}
            currentSettings={{
              prompt,
              width,
              height,
              selectedLora,
            }}
            onCustomPresetsChange={handleCustomPresetsChange}
          />
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <VStack spacing={4}>
              <FormControl>
                <Textarea
                  placeholder="Enter your image prompt"
                  value={prompt}
                  onChange={handlePromptChange}
                  size="md"
                  minHeight="250px"
                  resize="vertical"
                  data-attr="specialbox"
                  name="specialbox2"
                  borderRadius="md"
                />
                <Text
                  align="right"
                  fontSize="sm"
                  color={prompt.length > MAX_PROMPT_CHARS ? "red.500" : "gray.500"}
                >
                  ({prompt.length}/{MAX_PROMPT_CHARS})
                </Text>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="seed-input">Seed</FormLabel>
                <HStack width="100%" alignItems="center" spacing={4}>
                  <Input
                    id="seed-input"
                    placeholder="Seed"
                    value={seed}
                    onChange={(e) => setSeed(e.target.value)}
                    size="md"
                    width="140px"
                  />
                  <HStack spacing={1} alignItems="center">
                    <Checkbox
                      isChecked={randomizeSeed}
                      onChange={(e) => setRandomizeSeed(e.target.checked)}
                      size="md"
                      ml={-2}
                    />
                    <Text fontSize="sm" lineHeight="1" position="relative" top="2px">Randomize</Text>
                  </HStack>
                  <Tooltip label="A seed injects randomness into the image generation. Disabling 'Randomize' will allow you to recreate the same image given identical inputs." hasArrow>
                    <IconButton
                      icon={<InfoIcon />}
                      size="xs"
                      variant="ghost"
                      aria-label="Seed information"
                      ml={-4}
                    />
                  </Tooltip>
                </HStack>
              </FormControl>
              <DimensionInput
                value={width}
                onChange={setWidth}
                label="Width"
              />
              <DimensionInput
                value={height}
                onChange={setHeight}
                label="Height"
              />
              <FormControl>
                <FormLabel>Select Image Type</FormLabel>
                <ButtonGroup isAttached variant="outline">
                  <Button
                    onClick={() => setSelectedLora('face')}
                    colorScheme={selectedLora === 'face' ? 'blue' : 'gray'}
                  >
                    Face
                  </Button>
                  <Button
                    onClick={() => setSelectedLora('backside')}
                    colorScheme={selectedLora === 'backside' ? 'blue' : 'gray'}
                  >
                    Backside
                  </Button>
                </ButtonGroup>
              </FormControl>
              <FormControl>
                <FormLabel>Select Number of Images</FormLabel>
                <ButtonGroup isAttached variant="outline">
                  <Button
                    onClick={() => setNumImages(1)}
                    colorScheme={numImages === 1 ? 'blue' : 'gray'}
                  >
                    1
                  </Button>
                  <Button
                    onClick={() => setNumImages(2)}
                    colorScheme={numImages === 2 ? 'blue' : 'gray'}
                  >
                    2
                  </Button>
                  <Button
                    onClick={() => setNumImages(3)}
                    colorScheme={numImages === 3 ? 'blue' : 'gray'}
                  >
                    3
                  </Button>
                  <Button
                    onClick={() => setNumImages(4)}
                    colorScheme={numImages === 4 ? 'blue' : 'gray'}
                  >
                    4
                  </Button>
                </ButtonGroup>
              </FormControl>
            </VStack>
          </form>
          {showLoadingBar && (
            <Box width="100%">
              <Text textAlign="center" fontWeight="bold" mb={2}>
                {loadingMessage}
              </Text>
              <Progress
                value={loadingProgress}
                size="lg"
                colorScheme="blue"
                isAnimated
                hasStripe
              />
            </Box>
          )}

          {images.length > 0 && (
            <SimpleGrid columns={[1, Math.min(2, images.length)]} spacing={4} width="100%">
              {images.map((image, index) => (
                <Box
                  key={index}
                  width="100%"
                  paddingTop="100%"
                  position="relative"
                  cursor="pointer"
                  onClick={() => handleImageClick(index)}
                >
                  <Flex
                    position="absolute"
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                    alignItems="center"
                    justifyContent="center"
                    overflow="hidden"
                  >
                    <Image
                      src={image.image_url}
                      alt={`Generated Image ${index + 1}`}
                      objectFit="contain"
                      maxWidth="100%"
                      maxHeight="100%"
                    />
                  </Flex>
                </Box>
              ))}
            </SimpleGrid>
          )}
        </VStack>
      </Container>
      <Box
        position="fixed"
        bottom={0}
        left={0}
        right={0}
        height="80px"
        bg={bgColor}
        borderTop="1px solid"
        borderColor={borderColor}
        display="flex"
        alignItems="center"
        justifyContent="center"
        px={6}
        zIndex={1000}
      >
        <Flex justify="center" align="center" width="100%" maxW="container.xl">
          <HStack spacing={6} fontSize="lg">
            <Text whiteSpace="nowrap">
              <Text as="span" fontWeight="bold">
                {tokenCost}
              </Text>
              <Text as="span" color={tokenColor} fontWeight="bold">
                {' tokens'}
              </Text>
            </Text>
            <Text whiteSpace="nowrap">
              <Text as="span" color={estimatedTimeColor} fontWeight="bold">
                ~
              </Text>
              {estimatedTime.toFixed(0)}
              <Text as="span" color={estimatedTimeColor} fontWeight="bold">
                s
              </Text>
            </Text>
            <Button
              type="submit"
              colorScheme="customGreen"
              size="lg"
              isLoading={isLoading}
              loadingText="Generating"
              onClick={handleSubmit}
              fontSize="xl"
              px={8}
            >
              GENERATE
            </Button>
          </HStack>
        </Flex>
      </Box>
      <ImageModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        images={images}
        currentIndex={currentImageIndex}
        onIndexChange={handleModalIndexChange}
        onFavoriteToggle={handleFavoriteToggle}
      />
    </Box>
  );
}

export default CreatePage;