import React, { useState, useEffect, useCallback } from 'react';
import { Modal, ModalOverlay, Link, ModalContent, ModalBody, VStack, Button, Text, Input, FormControl, FormLabel, useToast, InputGroup, InputRightElement, Checkbox } from '@chakra-ui/react';
import { supabase } from './index';
import debounce from 'lodash.debounce';
import { usePostHog } from 'posthog-js/react'
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useRollbar } from '@rollbar/react';
import { sanitize } from 'dompurify';
import { CheckIcon, WarningIcon } from '@chakra-ui/icons';
import { isNonReportableError } from './errorUtils';

const USERNAME_REGEX = /^[a-zA-Z0-9_-]{3,20}$/;

function AuthModal({ isOpen, onClose, isCloseable, signingUp = false }) {
  const [isSignUp, setIsSignUp] = useState(signingUp);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [username, setUsername] = useState('');
  const [isCheckingUsername, setIsCheckingUsername] = useState(false);
  const [isUsernameAvailable, setIsUsernameAvailable] = useState(null);
  const [isUsernameValid, setIsUsernameValid] = useState(true);
  const [isOver18, setIsOver18] = useState(false);
  const [agreesToTOS, setAgreesToTOS] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const toast = useToast();
  const posthog = usePostHog();
  const navigate = useNavigate();
  const rollbar = useRollbar();

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password
      });
      if (error) throw error;
      toast({
        title: "Sign in successful",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      const user = data.user
      posthog.identify(user.email)
      rollbar.configure({
        payload: {
          person: {
            id: user.id,
            email: user.email
          }
        }
      });
      onClose();
      navigate('/create')
    } catch (error) {
      if (!isNonReportableError(error)) {
        rollbar.error("Error signing in", error)
      }

      toast({
        title: "Error signing in",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const checkUsernameAvailability = async (username) => {
    if (!username || !USERNAME_REGEX.test(username)) {
      setIsUsernameAvailable(null);
      setIsUsernameValid(!username || USERNAME_REGEX.test(username));
      return;
    }
    setIsCheckingUsername(true);
    const sanitizedUsername = sanitize(username)
    try {
      const response = await fetch('/api/username_check', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username: sanitizedUsername }),
      });

      const { availability, error } = await response.json();
      if (error) throw error;
      setIsUsernameAvailable(availability)
    } catch (error) {
      rollbar.error('Error checking username:', error);
      setIsUsernameAvailable(null);
    } finally {
      setIsCheckingUsername(false);
    }
  };

  // Debounce the username check to avoid too many API calls
  const debouncedCheckUsername = useCallback(
    debounce(checkUsernameAvailability, 3000),
    []
  );

  useEffect(() => {
    if (isSignUp && username) {
      debouncedCheckUsername(username);
    }
  }, [username, isSignUp]);

  useEffect(() => {
    if (isSignUp) {
      setPasswordsMatch(password === confirmPassword);
    }
  }, [password, confirmPassword, isSignUp]);

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      // Check username availability
      if (!isUsernameAvailable) {
        toast({
          title: "Username not available",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      if (!isOver18) {
        toast({
          title: "Age verification required",
          description: "You must confirm that you are over 18 to sign up.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      if (!agreesToTOS) {
        toast({
          title: "TOS and content policy agreement required",
          description: "You must agree to the terms of service and content policy.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      if (!passwordsMatch) {
        toast({
          title: "Passwords do not match",
          description: "Please make sure your passwords match.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      const sanitizedUsername = sanitize(username)

      const { error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: {
            username: sanitizedUsername,
          }
        }
      });

      if (error) throw error;

      toast({
        title: "Sign up successful",
        description: "Please check your email for verification and sign back in.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setIsSignUp(false); // Switch back to sign-in view
    } catch (error) {
      rollbar.error("Error signing up", error)
      toast({
        title: "Error signing up",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleClose = () => {
    if (isCloseable) {
      onClose();
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      const { error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: `${window.location.origin}/reset-password`,
      });
      if (error) throw error;
      toast({
        title: "Password reset email sent",
        description: "Please check your email for the password reset link.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setIsForgotPassword(false);
    } catch (error) {
      rollbar.error("Error sending password reset email", error);
      toast({
        title: "Error sending password reset email",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      closeOnOverlayClick={isCloseable}
      closeOnEsc={isCloseable}
      isCentered
      size="md"
    >
      <ModalOverlay backdropFilter="blur(10px)" />
      <ModalContent bg="gray.800" borderRadius="12px">
        <ModalBody p={6}>
          <VStack spacing={4}>
            {!isSignUp && !isForgotPassword ? (
              <form onSubmit={handleSignIn} style={{ width: '100%' }}>
                <VStack spacing={4}>
                  <FormControl>
                    <FormLabel color="gray.300">Email</FormLabel>
                    <Input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      borderRadius="8px"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel color="gray.300">Password</FormLabel>
                    <Input
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      borderRadius="8px"
                    />
                  </FormControl>
                  <Button type="submit" colorScheme="blue" width="100%" borderRadius="8px">
                    Sign In
                  </Button>
                  <Link color="blue.300" onClick={() => setIsForgotPassword(true)}>
                    Forgot your password?
                  </Link>
                  <Button onClick={() => setIsSignUp(true)} variant="outline" width="100%" borderRadius="8px">
                    Sign Up
                  </Button>
                </VStack>
              </form>
            ) : isForgotPassword ? (
              <form onSubmit={handleForgotPassword} style={{ width: '100%' }}>
                <VStack spacing={4}>
                  <FormControl>
                    <FormLabel color="gray.300">Email</FormLabel>
                    <Input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      borderRadius="8px"
                    />
                  </FormControl>
                  <Button type="submit" colorScheme="blue" width="100%" borderRadius="8px">
                    Send Reset Link
                  </Button>
                  <Button
                    onClick={(e) => {
                      e.preventDefault(); // Prevent form submission
                      setIsForgotPassword(false);
                    }}
                    variant="outline"
                    width="100%"
                    borderRadius="8px"
                  >
                    Back to Sign In
                  </Button>
                </VStack>
              </form>
            ) : (
              <form onSubmit={handleSignUp} style={{ width: '100%' }}>
                <VStack spacing={4}>
                  <FormControl>
                    <FormLabel color="gray.300">Username</FormLabel>
                    <InputGroup>
                      <Input
                        type="text"
                        value={username}
                        onChange={(e) => {
                          setUsername(e.target.value);
                          setIsUsernameValid(USERNAME_REGEX.test(e.target.value));
                        }}
                        borderRadius="8px"
                        isInvalid={!isUsernameValid}
                      />
                      <InputRightElement width="4.5rem">
                        {username && (
                          isCheckingUsername ? (
                            <Text fontSize="xs" color="gray.300">Checking...</Text>
                          ) : !isUsernameValid ? (
                            <Text fontSize="xs" color="red.500">Invalid</Text>
                          ) : isUsernameAvailable === true ? (
                            <Text fontSize="xs" color="green.500">Available</Text>
                          ) : isUsernameAvailable === false ? (
                            <Text fontSize="xs" color="red.500">Taken</Text>
                          ) : null
                        )}
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>
                  <FormControl>
                    <FormLabel color="gray.300">Email</FormLabel>
                    <Input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      borderRadius="8px"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel color="gray.300">Password</FormLabel>
                    <Input
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      borderRadius="8px"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel color="gray.300">Confirm Password</FormLabel>
                    <InputGroup>
                      <Input
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        borderRadius="8px"
                        isInvalid={!passwordsMatch && confirmPassword !== ''}
                      />
                      <InputRightElement>
                        {confirmPassword !== '' && (
                          passwordsMatch ? (
                            <CheckIcon color="green.500" />
                          ) : (
                            <WarningIcon color="red.500" />
                          )
                        )}
                      </InputRightElement>
                    </InputGroup>
                    {!passwordsMatch && confirmPassword !== '' && (
                      <Text color="red.500" fontSize="sm" mt={1}>
                        Passwords do not match
                      </Text>
                    )}
                  </FormControl>
                  <FormControl>
                    <Checkbox
                      isChecked={isOver18}
                      onChange={(e) => setIsOver18(e.target.checked)}
                      color="gray.300"
                    >
                      I confirm that I am 18 years of age or older
                    </Checkbox>
                  </FormControl>
                  <FormControl>
                    <Checkbox
                      isChecked={agreesToTOS}
                      onChange={(e) => setAgreesToTOS(e.target.checked)}
                      color="gray.300"
                    >
                      <Text as="span">
                        I agree to the{' '}
                        <Link as={RouterLink} to="/termsofservice" color="blue.500" textDecoration="underline">
                          Terms of Service
                        </Link>
                        {' '}and {' '}
                        <Link as={RouterLink} to="/contentpolicy" color="blue.500" textDecoration="underline">
                          Content Policy
                        </Link>
                        {' '}
                      </Text>
                    </Checkbox>
                  </FormControl>
                  <Button
                    type="submit"
                    colorScheme="blue"
                    width="100%"
                    borderRadius="8px"
                    isDisabled={isSignUp && (!isUsernameAvailable || !isOver18 || !agreesToTOS || !passwordsMatch)}                  >
                    Sign Up
                  </Button>
                  <Text color="gray.400">Already have an account?</Text>
                  <Button onClick={() => setIsSignUp(false)} variant="outline" width="100%" borderRadius="8px">
                    Sign In
                  </Button>
                </VStack>
              </form>
            )}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default AuthModal;