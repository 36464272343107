import React from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, Box, Flex, Text, useTheme, useMediaQuery } from '@chakra-ui/react';
import { LuCrown, LuX, LuCheck } from "react-icons/lu";
import { premiumCrownColor, blueberryColor } from './colors';
import { BlueberryIcon } from './CustomIcons';

const TierChart = () => {
	const theme = useTheme();
	const [isMobile] = useMediaQuery("(max-width: 48em)");

	const tiers = [
		{ name: 'Free', color: 'blue.700', textColor: 'white' },
		{ name: 'Stuffed', color: 'blue.800', textColor: 'white' },
		{ name: 'Bursting', color: 'blue.900', textColor: 'white' }
	];

	const features = [
		{
			name: 'Monthly Cost',
			values: ['$0', '$10', '$20']
		},
		{
			name: '# of image generations',
			values: ['10 daily', '1500 monthly', '5000 monthly']
		},
		{
			name: 'Premium badge',
			values: [
				<LuX color="red" />,
				<LuCrown size={isMobile ? 12 : 16} color={premiumCrownColor} />,
				<BlueberryIcon size={isMobile ? 12 : 16} color={blueberryColor} />
			]
		},
		{
			name: 'Early access to new features',
			values: [<LuX color="red" />, <LuCheck color="green" />, <LuCheck color="green" />]
		},
		{
			name: 'Supporting the development of spherify.ai',
			values: [<LuX color="red" />, <LuCheck color="green" />, <LuCheck color="green" />]
		}
	];

	return (
		<Box overflowX="auto">
			<Table variant="simple" size={isMobile ? "sm" : "md"}>
				<Thead>
					<Tr>
						<Th></Th>
						{tiers.map((tier, index) => (
							<Th key={index} textAlign="center" bg={tier.color} px={0}>
								<Text color={tier.textColor} fontWeight="bold" fontSize={isMobile ? "xs" : "sm"}>{tier.name}</Text>
							</Th>
						))}
					</Tr>
				</Thead>
				<Tbody>
					{features.map((feature, index) => (
						<Tr key={index}>
							<Td>
								<Text fontWeight="bold" textAlign="left" fontSize={isMobile ? "xs" : "sm"}>{feature.name}</Text>
							</Td>
							{feature.values.map((value, valueIndex) => (
								<Td key={valueIndex} bg={tiers[valueIndex].color} px={isMobile ? 2 : 4}>
									<Flex justifyContent="center" alignItems="center" height="100%">
										{typeof value === 'string' ? (
											<Text textAlign="center" color={tiers[valueIndex].textColor} fontSize={isMobile ? "xs" : "sm"}>{value}</Text>
										) : (
											<Box as="span">{value}</Box>
										)}
									</Flex>
								</Td>
							))}
						</Tr>
					))}
				</Tbody>
			</Table>
		</Box>
	);
};

export default TierChart;