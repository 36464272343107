
import { FaDiscord } from 'react-icons/fa';
import { FaPatreon } from "react-icons/fa6";

export const MAX_IMG_SIZE = 2048
export const MAX_PROMPT_CHARS = 2000;
export const MAX_SEED_LENGTH = 100;
export const PATREON = "patreon"
export const DEVIANTART = "deviantart"
export const SECONDS_TO_MILLISECONDS = 1000;
export const socialLinks = [
	{ name: 'Discord', url: 'https://discord.gg/cQtSgnFcDk', icon: FaDiscord },
	{ name: 'Patreon', url: 'https://www.patreon.com/spherify', icon: FaPatreon },
];
export const notEnoughTokensError = "Not enough tokens."
